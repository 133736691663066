.Sub-Menu ul {
  background-color: #426e7a;
}

.Sub-Menu li {
  color: white;
}

.css-zvumbm{
  background-color: #5cb5cd !important;
}

.css-14wpos0 >.ps-menu-button:hover{
  background-color: #426e7a !important;
}

.css-1k8ltj6 >.ps-menu-button:hover {
  background-color: #426e7a !important;
}

.css-447fye >.ps-menu-button:hover{
  background-color: #426e7a !important;
}

.css-12w9als{
  font-size: 14px !important;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
}


.css-1tqrhto > .ps-menu-button:hover {
  background-color: #426e7a !important;
}

/* .css-12vqd6d {
  background: #00056b ;
} */

.css-bdgam1 {
  background-color: #325c67 !important;
}

.Admin {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.display {
  display: none;
}

.sidebar {
  display: flex;
  /* background:black;
  border-color:black !important; */
}

.logo {
  height: 64px;
  padding-left: 20px;
  width: 200px;
}
.css-12vqd6d .ps-sidebar-container{
  padding-top: 13px !important;
}

.toggle-button {
  background-color: #000000;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  text-align: center;
  position: relative;
  right: 15px;
  z-index: 10;
  transition: all 0.3s ease;
  cursor: pointer;
}
.Search {
  position: relative;
  top: 43px;
}

.toggle-icon {
  position: relative;
  top: 2px;
  font-size: 18px;
  color: #fff;
  transition: transform 0.3s ease;
}

.css-12vqd6d {
  background: #00056b;
}

.table-container {
  width: calc(100% - 300px) !important;
}
@media (max-width: 480px) {


  .Search {
    top: 0px;
  }
  .table-container {
    width: calc(100% - 120px) !important ;
  }
}

/* @media (min-width:480px) and (max-width:600px) {
    .table-container{
  width:calc(100% - 80px) !important ;
}
} */

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon,
.icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main";
  height: 100vh;
}

/* Header  */
.header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}

.menu-icon {
  display: none;
}
/* End Header  */

/* Sidebar  */
#sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #263043;
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 30px;
  margin-bottom: 30px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
}

.sidebar-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #9e9ea4;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  /*
    we want the z-index of the sidebar higher so that
    the charts are not showing over the sidebar 
    on small screens
  */
  z-index: 12 !important;
}

/* End Sidebar  */

/* Main  */
.main-container {
  grid-area: main;
  overflow-y: auto;

  color: #524e4ef2;
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.main-cards {
  display: grid;
  /* padding:20px; */
  gap: 20px;
  margin: 15px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  height: 110px;
  width: 220px;
}

.card:first-child {
  /* background: linear-gradient(
    90deg,
    rgb(0, 79, 120) 0%,
    rgb(0, 79, 120) 15%,
    rgb(28, 143, 166) 68%
  ); */

  background-color: white;
  
  margin-right: 35px;
  font-size: 14px !important;
}

.card:nth-child(2) {
  /* background: linear-gradient(
    90deg,
    rgb(0, 79, 120) 0%,
    rgb(0, 79, 120) 15%,
    rgb(28, 143, 166) 68%
  ); */
  background-color: white;
  font-size: 14px !important;
}

.card:nth-child(3) {
  /* background: linear-gradient(
    90deg,
    rgb(0, 79, 120) 0%,
    rgb(0, 79, 120) 15%,
    rgb(28, 143, 166) 68%
  ); */
  background-color: white;
  margin-left:35px;
  font-size: 14px !important;
}

@media (max-width: 800px){
  .card:nth-child(3) {
    margin-left:0px;
  }
}

.card:nth-child(4) {
  background-color: #d50000;
}
.background{
  background:#eeeeee;
}
.card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: larger;
}

.card-inner > .card_icon {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

/* End Main  */

/* Medium <= 992px */

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "header"
      "main";
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */

@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }




}



a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
}



.row-cards{
  display:flex;
}

@media (max-width:420px){
  .card {
    width: 190px;
  }

  .dashboard_graph{
    height: 258px;
   width: 208px !important;
   margin-bottom:130px;
 }
}


@media (min-width:420px) and (max-width: 760px) {
  .card {
    width: 260px;
  }

  .dashboard_graph{
    height: 258px;
   width: 260px !important;
   margin-bottom:130px;
 }
}

@media (max-width: 760px){
  .row-cards{
    flex-direction:column;
    gap:20px;
  }
}

.label{
  display:flex;
  align-items:center;
  font-size:13px;
}

.MuiInputBase-root{
  margin:4px;
  height:38px;
 
}

.side-date{
width:100%;
display:flex;
flex-direction:column;
margin:15px;
align-items:end;
font-size:13px;
}

.side-input{
 justify-content:flex-end;
 display:flex;
 flex-direction:row;
 width:100%;

}

.input-side{
  width:150px;
  font-size:12px;

}

/* .side-input{
  width:20%;
} */

.accordion{
    position: relative;
    /* left: 73px; */
    width: 600px;
   right:240px;
   bottom:200px;
}

.css-ho34lf-MuiPaper-root-MuiAppBar-root{
  background-color:#388f91!important;
}

@media (min-width: 1050px){
.css-4uuiz5-MuiContainer-root {
    max-width: 1200px !important;
}
}


@media (max-width: 780px) {
  .css-1eks31a-MuiTypography-root{
    position:absolute;
    left:195px !important;
  }


  }
  
  @media (max-width: 480px) {
  .css-1eks31a-MuiTypography-root{
    position:absolute;
    left:120px !important;
  }
  .css-1udmlmp-MuiTypography-root{
    left: 120px !important;
    font-size: 20px !important;
  }
  }

  .css-1dpvwy8{
    border-color:black !important;
  }

  .dashboard_graph{
     height: 258px;
    width: 430px;
    margin-bottom:130px;
  }

/* .css-1vuxth3-MuiBarElement-root{
  fill:white !important;
} */

/* .MuiChartsAxis-label{
  fill:white !important;
} */
/* .css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-tick{
  stroke:white !important;
} */

/* .MuiChartsAxis-tickLabel{
  fill:white !important;
} */



.css-xmy3qh{
  border-right-style:none !important;
  width:220px !important;
  min-width:170px !important;
}

.css-hyum1k-MuiToolbar-root{
  min-height:45px !important;
}

.css-8444sg {
  background-color: #1c3976;
}
