.main-container1 {
    display: flex;
    flex-direction: row;
    background-color: #eae7eb;
    height: 692px;
  }
  
  .main-cards1 {
    margin: auto; /* Center the cards horizontally */
    text-align: center;
    padding-left: 320px;
  }
  
  .row-cards1 {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }
  
  .card1 {
    width: 300px;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-inner1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .card1 h1 {
    margin-top: 20px;
  }
  
  .card1 img {
    width: 200px;
    height: 150px;
    margin-top: 30px;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .row-cards1 {
      flex-direction: column;
      align-items: center;
    }
  
    .card1 {
      margin-bottom: 20px;
    }
  }

/* 
  #banner_name{

  } */

  
