.main-cards {
    display: grid;
    /* padding: 20px; */
    gap: 20px;
    margin: 15px 0;
  }
  .card-inner-reports {
    align-items: center;
    justify-content: space-between;
    font-size: larger;
  }
  
  .card-reports {
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    height: 110px;
    width: 230px;
    justify-content: center;
  }
  
  .card-reports:first-child {
    background: white;
    border: 1px solid black;
    margin-right: 25px;
   
    /* margin-bottom: 50px; */
  }

  .size{
    height: 10px;
  }
  
  .card-reports:nth-child(2) {
    background: white;
    border: 1px solid black;
    /* margin-bottom: 500px; */
  }
  
  .card-reports:nth-child(3) {
    background: linear-gradient(
      90deg,
      rgb(0, 79, 120) 0%,
      rgb(0, 79, 120) 15%,
      rgb(28, 143, 166) 68%
    );
    margin-left: 35px;
  }
  
  .card-reports:nth-child(4) {
    background-color: #d50000;
  }
  
  .card-reports-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    height: 110px;
    width: 230px;
    justify-content: center;
  }
  
  .card-reports-button:first-child {
    color: black;
    margin-right: 100px;
    margin-left: 200px;
    margin-bottom: 320px;
  }
  
  .card-reports-button:nth-child(2) {
    color: black;
    margin-bottom: 320px;
  }
  
  .card-r {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    height: 70px;
    width: 220px;
  }
  
  .card-r:first-child {
    /* background: linear-gradient(
      90deg,
      rgb(0, 79, 120) 0%,
      rgb(0, 79, 120) 15%,
      rgb(28, 143, 166) 68%
    ); */
    background-color: white;
    margin-right: 35px;
  }
  
  .card-r:nth-child(2) {
    /* background: linear-gradient(
      90deg,
      rgb(0, 79, 120) 0%,
      rgb(0, 79, 120) 15%,
      rgb(28, 143, 166) 68%
    ); */
    background-color: white;
  }
  
  .card-r:nth-child(3) {
    /* background: linear-gradient(
      90deg,
      rgb(0, 79, 120) 0%,
      rgb(0, 79, 120) 15%,
      rgb(28, 143, 166) 68%
    ); */
    background-color: white;
    margin-left:35px;
  }
  
  .card-r:nth-child(4) {
    background-color: #d50000;
  }

  @media (max-width: 650px){
    .card-reports:first-child {
       
       
        margin-bottom: 0px;
      }

      .card-r:nth-child(3) {
    
        margin-left:0px;
      }
  }

  @media (min-width: 650px) and (max-width: 760px){
    .card-reports:first-child {
       
       
        margin-bottom: 0px;
      }

      .card-r:nth-child(3) {
    
        margin-left:0px;
      }
  }

  @media (min-width: 760px) and (max-width: 860px){
    .card-reports:first-child {
      background: white;
      border: 1px solid black;
      margin-right: 25px;
     
      margin-bottom: 500px;
    }
  }


  @media screen and (max-width: 1000px) {
    .extras{
      display: block;
    }
    .extra {
      display: none;
    }

  }
  
  @media screen and (max-width: 760px) {

    .extra {
      display: block;
    }
    .extras {
      display: none;
    }
 
  }

  @media screen and (min-width: 1000px) {

    .extras {
      display: none;
    }
 
  }

  .css-66gjpw-MuiResponsiveChart-container>svg{
    margin-right: 311px;
  }

