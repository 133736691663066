#form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  margin-top: 10px;
  text-align: center;
  border-radius: 20px;
  padding-top: 0px;

}

@media (min-width: 300px) and (max-width: 560px){
  #form {
    padding: 1px !important;
  }
}

@media (min-width:"560px"){
  #form { 
    padding: 50px !important;
  }

}

.grid2 {
  margin-top: 20px;
}

.form {
  max-width: auto;
  padding: 0;
}

.form input {
  width: 200px;
}

.date {
  width: 800px;
}


.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 20px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #306ef3 !important;
}

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root{
  width: 20% !important;
  background-color: #306ef3 !important;
}

.display {
  display: none;
}

@media screen and (max-width: 1000px) {
  .display {
    display: block;
  }
  .dispaly {
    display: none;
  }
  .dashboard_graph {
    width: auto;
  }
}

@media screen and (max-width: 820px) {
  .display {
    display: block;
  }
  .dispaly {
    display: none;
  }
  .dashboard_graph {
    width: 220px;
  }
}