@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
  margin: 0;
}

.login {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* font-family: "Montserrat", sans-serif; */
  height: 100vh;
  /* margin: -20px 0 50px; */
  background-image: url(../Components/background_logo.jpg);
  


}

.login h1 {
  font-weight: bold;
  margin: 0;
}

#hello {
  font-weight: bold;
  font-size: 27px !important;
  margin: 0;
}

.login h2 {
  text-align: center;
}

.login p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.login span {
  font-size: 12px;
}

.login a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.login button {
  border-radius: 20px;
  border: 1px solid #2bffd4;
  background-color: #0abcd7;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.login button:active {
  transform: scale(0.95);
}

.login button:focus {
  outline: none;
}

.login button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #86562e59;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 470px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 100%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  
  background: -webkit-linear-gradient(to right, #f1f1f1, #00b4e6);
  background: linear-gradient(to right, #f1f1f1, #00b4e6);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

#logo {
  height: 100px;
  margin-bottom: 32px;
}

/* For Phones */
@media only screen and (max-width: 600px) {
  form {
    background-color: #ffffff;
    display: flex;
    /* border: 2px solid red; */
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
    padding-bottom: 260px;
  }

  .container {
    width: 100%;
    padding: 0 20px; /* Adjust padding for smaller screens */

  }
  .form-container {
    width: 100%;
    position: relative;

  }
  .sign-in-container,
  .sign-up-container {
    width: 100%;
    
  }
  .overlay-container {
    width: 100%;
    left: 0;
    /* z-index: -1000; */
    /* border: 2px solid blue; */
    margin-top: 250px;
    height: 250px;
  }
  .overlay {
    width: 100%;
    /* border: 2px solid blue; */
  }
  .overlay-panel {
    /* border: 2px solid blue; */
    width: 194%;
    /* padding: 0 20px; */
    background: linear-gradient(to right, #f1f1f1, #00b4e6);
    

    position: absolute;
    left: 162px;
  

    height: 270px;
 
  }
  #logo {
    height: 100px; /* Adjust logo size for smaller screens */
  }

  #hello {
    font-weight: bold;
    margin: 0;
    font-size: 20px;
    margin-bottom: 75px;
  }
}

/* For Tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .container {
    max-width: 561px; /* Adjust container max-width for tablets */
  }

  #logo {
    height: 80px; /* Adjust logo size for smaller screens */
  }

  #hello {
    font-weight: bold;
    margin: 0;
    font-size: 30px;
  }
}

/* For Laptops and Desktops */
@media only screen and (min-width: 1025px) {
  /* Adjust styles for laptops and desktops */
  .container {
    max-width: 1024px; /* Adjust container max-width for larger screens */
  }
}

/* @media only screen and (min-width: 336px) and (max-width: 600px) {
	#container{
		display: flex;

	}
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
	
  }

  @media only screen and (min-width: 901px) and (max-width: 1200px) {
	
  }

  @media only screen and (min-width: 1201px) {
	
  } */
